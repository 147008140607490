import React from "react";
import "./App.css";
// import logoattestations from "./assets/attestations.png";
// import logoscanfood from "./assets/scanfood.png";
// import logoruesdeparis from "./assets/ruesdeparis.png";
// import logotvsports from "./assets/tvsports.png";

import Obfuscate from "react-obfuscate";
// import Header from "./components/Header";
// import AppRow from "./components/AppRow";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Wheel from "./components/Wheel";

// const AppList = [
// 	// {
// 	// 	title: "Attestation de déplacement",
// 	// 	logo: logoattestations,
// 	// 	url: "https://play.google.com/store/apps/details?id=com.attestsortiefr",
// 	// 	video: require("./assets/videos/attestation_video.mp4"),
// 	// },
// 	{
// 		title: `ScanFood - Scan de produits alimentaires`,
// 		logo: logoscanfood,
// 		url: "https://play.google.com/store/apps/details?id=com.scanfood",
// 		video: require("./assets/videos/scanfood_video.mp4"),
// 	},
// 	// {
// 	// 	title: "TV Sports",
// 	// 	logo: logotvsports,
// 	// 	url: "https://play.google.com/store/apps/details?id=com.programtvsport",
// 	// },
// 	// {
// 	// 	title: "Histoire des rues de Paris",
// 	// 	logo: logoruesdeparis,
// 	// 	url: "https://play.google.com/store/apps/details?id=com.ruesdeparis",
// 	// },
// ];

function App() {
	const renderAppsList = () => {
		return (
			<div className='content'>
				{/* {AppList.map((entry, index) => {
					return <AppRow key={index} data={entry} />;
				})} */}
			</div>
		);
	};

	return (
		<Router>
			{/* <Header /> */}
			<Switch>
				{/* <Route path='/sarah'>
					<Wheel
						name={"Sarah"}
						textCadeau={
							"Ton cadeau : Le calendrier des fruits et légumes Le Primeur & un filtre à eau pour vos randonnées ! (pense à m'envoyer ton adresse 😁)"
						}
						images={[
							require("./assets/sarah1.png"),
							require("./assets/sarah2.png"),
							require("./assets/sarah3.png"),
							require("./assets/filtre-eau.png"),
							require("./assets/filtre-eau2.png"),
						]}
					/>
				</Route>

				<Route path='/felix'>
					<Wheel
						name={"Félix"}
						textCadeau={
							"Ton cadeau : Le pack Super Heroes Lab de LEGO DUPLO ! (pense à m'envoyer ton adresse 😁)"
						}
						images={[
							require("./assets/felix3.png"),
							require("./assets/felix4.png"),
							require("./assets/felix5.png"),
						]}
					/>
				</Route>
				<Route path='/verocha'>
					<Wheel
						name={"Véro"}
						textCadeau={
							"Ton cadeau : Un cofret acrylique Sennelier bois noir de 13 tubes !"
						}
						images={[require("./assets/vero1.png")]}
					/>
				</Route>
				<Route path='/philr'>
					<Wheel
						name={"Philippe"}
						textCadeau={
							"Ton cadeau : Un abonnement d'un an pour forêt privée & un filtre à eau pour vos randonnées !"
						}
						images={[
							require("./assets/phil1.png"),
							require("./assets/filtre-eau.png"),
							require("./assets/filtre-eau2.png"),
						]}
					/>
				</Route>
				<Route path='/claramar'>
					<Wheel
						name={"Clara"}
						textCadeau={
							'Ton cadeau : Un tapis de Yoga écolo en liège & des HAPPY SOCKS "Stripe 3 Pack" (ou autre choix)!'
						}
						images={[
							require("./assets/tapis-yoga1.png"),
							require("./assets/tapis-yoga2.png"),
							require("./assets/tapis-yoga3.png"),
							require("./assets/clara4.png"),
							require("./assets/clara5.png"),
						]}
					/>
				</Route>
				<Route path='/leomar'>
					<Wheel
						name={"Léo"}
						textCadeau={
							"Ton cadeau : Une cafetière à l'italienne bellegosse Bialetti & un Tshirt running manches longues pour courrir en hiver !"
						}
						images={[
							require("./assets/leo1.png"),
							require("./assets/leo2.png"),
							require("./assets/leo3.png"),
							require("./assets/leo4.png"),
						]}
					/>
				</Route> */}
				<Route path='/'>
					<>
						<content className='container'>
							<div className='content'>
								<span className='headerTitle'>
									Application mobiles
								</span>
								{renderAppsList()}
								{/* <iframe
									title="twitch-joons"
									src="https://player.twitch.tv/?channel=joonsttv&parent=www.glilab.io&muted=false"
									height="300"
									width="60%"
									frameBorder="0"
									scrolling="no"
									allowFullScreen="true"
								></iframe> */}
								<p
									style={{
										zIndex: 2,
										textAlign: "center",
										marginTop: 50,
										fontSize: 16,
									}}
								>
									En cas de bugs / suggestions / demandes:{" "}
									<Obfuscate email='contact.glilab@gmail.com' />
								</p>
							</div>
						</content>
					</>
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
